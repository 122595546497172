html, body, #root {
    width: 100%;
    height: 100%;
    font-size: 16px;
}

@font-face {
    font-family: 'FontAwesome';
    src: url('/fonts/fontawesome-webfont.eot');
    src: url('/fonts/fontawesome-webfont.eot?#iefix') format('embedded-opentype'), url('/fonts/fontawesome-webfont.woff') format('woff'), url('/fonts/fontawesome-webfont.ttf') format('truetype'), url('/fonts/fontawesome-webfont.svg#FontAwesome') format('svg');
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Maven Pro", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #F9F9F9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.select-item {
    padding: 1px !important;
}

.MuiPickersToolbar-toolbar {
    background-color: rgb(59, 89, 114);
}

.MuiTypography-subtitle1 {
    font-size: 1.6rem;
}

.MuiPickersToolbarText-toolbarTxt {
    color: rgb(255 255 255 / 90%);
}

.MuiPickersDay-daySelected {
    background-color: rgb(59, 89, 114);
}